'use client'
import { useEffect } from 'react'
import { usePathname } from '@/navigation'
import { useLocale } from 'next-intl'
import useVipAuth from '@/store/vipAuth'
import useVipMeta from '@/store/vipMeta'
import '@/styles/vip/vip.css'
import { useReCaptcha } from 'next-recaptcha-v3'

export default function BBeyondLayout({
  children, // will be a page or nested layout
}) {
  const pathname = usePathname()
  const locale = useLocale()
  const { executeRecaptcha, loaded: isRecaptchaLoaded } = useReCaptcha()

  useEffect(() => {
    const getTokenAndFetchUserMe = async () => {
      if (!isRecaptchaLoaded) return
      const token = await executeRecaptcha('vip_fetch_user_me')
      const mayFetchUserMe = useVipAuth.getState().mayFetchUserMe
      mayFetchUserMe(token, locale)
    }
    getTokenAndFetchUserMe()
  }, [pathname, isRecaptchaLoaded, executeRecaptcha])

  useEffect(() => {
    useVipMeta.getState().fetchVipMeta()
  }, [])

  return (
    <>
      {/* Include shared UI here e.g. a header or sidebar */}
      <div id="vip">{children}</div>
    </>
  )
}
